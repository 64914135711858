"use client";
/*
 * Documentation:
 * CTA pop up — https://app.subframe.com/feb895689ba0/library?component=CTA+pop+up_7c7b2321-0471-4d69-9089-01f04a4004e1
 * ASButton — https://app.subframe.com/feb895689ba0/library?component=ASButton_71931235-bb08-41fa-ad00-ec9684e17f79
 * Powered by — https://app.subframe.com/feb895689ba0/library?component=Powered+by_251400c5-8436-4c51-980b-bee2251053fb
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { AsButton } from "./AsButton";
import { PoweredBy } from "./PoweredBy";

interface CtaPopUpRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  image?: string;
  blur?: boolean;
  className?: string;
}

const CtaPopUpRoot = React.forwardRef<HTMLElement, CtaPopUpRootProps>(
  function CtaPopUpRoot(
    { text, image, blur = false, className, ...otherProps }: CtaPopUpRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/7c7b2321 flex flex-col items-center justify-center gap-2 rounded-lg bg-white px-2 py-4 shadow-pop-up-shadow",
          { "bg-[#ffffff80] backdrop-blur-sm": blur },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <AsButton
          className="h-14 w-full flex-none"
          variant="default"
          leadingIcon={null}
          trailingIcon={null}
          text="Looks good"
          size="lg"
          loader={false}
          disabled={false}
          disabledSecondary={false}
        />
        <PoweredBy text="Powered by" />
      </div>
    );
  }
);

export const CtaPopUp = CtaPopUpRoot;
