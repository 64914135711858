"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * Receipt end — https://app.subframe.com/feb895689ba0/library?component=Receipt+end_a926eb7c-dad2-4c2f-86aa-4613683c8daa
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface ReceiptEndRootProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  className?: string;
}

const ReceiptEndRoot = React.forwardRef<HTMLElement, ReceiptEndRootProps>(
  function ReceiptEndRoot(
    { image, className, ...otherProps }: ReceiptEndRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "flex w-112 flex-col items-center gap-2 mobile:w-full",
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        {image ? (
          <img
            className="w-full grow shrink-0 basis-0 object-contain"
            src={image}
          />
        ) : null}
      </div>
    );
  }
);

export const ReceiptEnd = ReceiptEndRoot;
